import React from "react"
import { useEffect } from "react"
import { navigate } from "gatsby"

export default function DailyPlanner() {
  useEffect(() => {
    navigate("/products/print-books/3-month-daily-planner-thought-workbook/")
  }, [])
  return <div> </div>
}
